
.projects-wrapper {
  /* background-color: rgb(212, 224, 255); */
  /* background-color: aliceblue; */
  /* background-color: white; */
  /* background-color: rgb(149, 209, 204); */
  /* background-color: rgb(246, 242, 212); */
  background-color: rgb(4, 28, 50);
  margin-top: 0px;
  padding-bottom: 50px;
  padding-left: 10%;
  padding-right: 10%;
}

.projects-title {
  color: white;
}

.projects {
  padding-top: 5%;
  padding-bottom: 5%;
  margin: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.project-cards {
  display: flex;
  flex-wrap: wrap;
  justify-content: left;
  gap: 50px;
}
