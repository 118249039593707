.faq-wrapper {
  background-color: rgb(4, 28, 50);
  margin-top: 0px;
  padding-left: 10%;
  padding-right: 10%;
}
  
.faq-title {
  color: white;
}

.faq {
  padding-top: 5%;
  padding-bottom: 5%;
  margin: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 40px;
}