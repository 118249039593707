.experience-card {
  border-radius: 7px;
  border-width: 1.5px;
  /* border-style: solid; */
  /* border-color: lightgray; */
  background-color: rgb(5, 45, 63);
  /* box-shadow: 2px 2px 6px rgb(59, 59, 59); */
  padding-left: 20px;
  padding-right: 20px;
  /* padding-top: 20px;
  padding-bottom: 20px; */
  display: flex;
  flex-direction: row;
  transition: 0.3s;
}

.experience-card:hover {
  /* background-color: rgb(247, 247, 247); */
  background-color: rgb(5, 54, 77);
}

.experience-pic {
  /* margin-top: 20px;
  margin-bottom: 20px;
  margin-right: 20px; */
  /* min-width: 300px; */
  min-height: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  /* width: 100%;
  height: 100%; */
  max-width: 400px;
  object-fit: cover;
  /* height: auto; */
}

.experience-pic-wrapper {
  min-width: 300px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}

.experience-desc {
  /* display: flex;
  flex-direction: column;
  align-items: left; */
  margin-left: 20px;
  text-align: left;
  color: rgb(202, 202, 202);
}

.experience-desc-title {
  margin-bottom: -15px;
  /* color: white; */
}

.experience-card-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  margin-bottom: 20px;
  gap: 20px;
}

.experience-pic-mobile {
  display: none;
}

@media screen and (max-width: 800px) {
  .experience-card {
    flex-direction: column;
    padding-top: 20px;
  }

  .experience-desc {
    margin-left: 0px;
    text-align: center;
  }

  .experience-pic-wrapper {
    /* flex-direction: row;
    justify-content: center; */
    /* width: 100%;
    height: 100%;
    object-fit: contain;
    min-width: none;
    min-height: none; */
    display: none;
  }

  .experience-pic-mobile {
    display: block;
    width: 100%;
    object-fit: cover;
    height: auto;
  }

  .experience-card-buttons {
    justify-content: center;
  }
}