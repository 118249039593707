.project-card {
  /* background-color: white; */
  background-color: rgb(5, 45, 63);
  /* border-style: solid; */
  border-radius: 7px;
  /* border-color: lightgrey; */
  border-width: 1.5px;
  /* box-shadow: 2px 2px 6px rgb(59, 59, 59); */
  padding-left: 20px;
  padding-right: 20px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
  color: rgb(202, 202, 202);
  /* cursor: pointer; */ 
}

.project-card:hover {
  /* background-color: rgb(240, 240, 240); */
  /* background-color: rgb(247, 247, 247); */
  background-color: rgb(5, 54, 77);
}

.project-card-pic {
  margin-top: 20px;
  /* margin-bottom: 20px; */
  /* margin-right: 20px; */
  width: 100%;
  /* max-width: 350px; */
  object-fit: cover;
  height: auto;
}

.project-card-title {
  margin-bottom: -15px;
  /* color: white; */
}

.project-card-buttons {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  margin-bottom: 20px;
  gap: 20px;
}

@media screen and (max-width: 800px) {
  .project-card {
    max-width: 100%;
    text-align: center;
  }
}