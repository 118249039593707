.about-wrapper {
  /* background-color: antiquewhite; */
  /* background-color: aliceblue; */
  /* background-color: white; */
  /* background-color: rgb(149, 209, 204); */
  /* background-color: rgb(246, 242, 212); */
  /* background-color: rgb(34, 87, 126); */
  background-color: rgb(4, 28, 50);
  /* color: rgb(255, 254, 246); */
  color: rgb(202, 202, 202);
  padding-left: 10%;
  padding-right: 10%;
  margin-bottom: 0px;
}

.about {
  padding-top: 5%;
  padding-bottom: 5%;
  margin: auto;
  max-width: 1200px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.about-title {
  color: white;
}

.about-desc {
  padding-right: 5%;
  padding-bottom: 5%;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.resume-button {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: left;
}

.profile-pic-wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profile-pic-mobile {
  display: none;
}

.profile-pic {
  max-width: 400px;
  border: none;
  border-radius: 7px;
}

@media screen and (max-width: 800px){
  /* * {margin: 0; padding: 0;} */
  .profile-pic-wrapper {
    display: none;
  }

  /* .profile-pic-wrapper-mobile {
    display: flex;
    flex-direction: row;
    justify-content: center;
    /* padding-left: 50px;
    padding-right: 50px; 
  }*/

  .profile-pic-mobile {
    display: block;
    width: 100%;
    object-fit: contain;
  }

  .about {
    flex-direction: column;
    align-items: center;
    /* margin-left: 50px;
    margin-right: 50px; */
  }

  .resume-button {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

}