.home {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  /* background-image: url('../images/wp2555196-blue-background.png'); */
  /* background-image: url('../images/vincentiu-solomon-ln5drpv_ImI-unsplash.jpg'); */
  background-image: url('../images/nathan-anderson-L95xDkSSuWw-unsplash.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
}

.hello {
  font-size: 5pc;
}

.name {
  padding-left: 15%;
  padding-right: 15%;
  font-size: 2pc;
}

.scroll-icon {
  max-height: 30px;
  filter: invert(1);
}