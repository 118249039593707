.navbar-wrapper {
  /* background-color: rgb(123, 203, 206); */
  /* background-color: aliceblue; */
  /* background-color: white; */
  background-color: rgb(5, 45, 63);
  z-index: 1;
  opacity: 0.97;
  /* box-shadow: 1px 1px 10px 1px rgb(59, 59, 59); */
  /* border-style: none none solid none; */
  /* border-color: rgb(224, 224, 224); */
  /* border-color: lightgray; */
  width: 100%;
  min-height: 50px;
  position: fixed;
}

.navbar {
  margin: auto;
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;
  gap: 50px;
  min-height: 50px;
  max-width: 1200px;
}

.navbuttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: right; 
}

.nav-button {
  display: block;
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;
  border: none;
  text-align: center;
  text-decoration: none;
  font-size: 20px;
  transition: 0.3s;
  cursor: pointer;

  /* background-color: rgb(123, 203, 206); */
  /* background-color: aliceblue; */
  /* background-color: white; */
  /* color: black; */
  color: rgb(202, 202, 202);
}

.nav-button:hover {
  /* background-color: rgb(100, 178, 180); */
  /* background-color: rgb(212, 219, 226); */
  background-color: rgb(5, 54, 77);
}

.navbar-logo {
  max-height: 50px;
  /* padding-left: 150px; */
}

.navtitle {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: left;
  /* justify-content: left; */
  text-align: left;
  margin-top: -6px;
  margin-bottom: -6px;
  padding-left: 20px;
  min-width: 200px;
  color: white;
}

.boardname {
  margin-bottom: -6px;
}

.navbar-menu {
  display: none;
}

@media screen and (max-width: 800px){
  .navbuttons {
    display: none;
  }

  .navbar-menu {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: right;
    border: none;
    background: none;
  }

  .navbar-menu-button {
    border: none;
    background: none;
  }

  .navbar-menu-icon {
    max-height: 30px;
    padding-right: 20px;
    filter: invert(1);
  }

  .nav-button {
    display: block;
    padding-top: 20px;
    padding-bottom: 20px;
    padding-left: 10px;
    padding-right: 50px;
    border: none;
    height: auto;
    text-align: left;
  
    /* background-color: rgb(123, 203, 206); */
    /* background-color: aliceblue; */
    background-color: rgb(5, 45, 63);
    color: rgb(202, 202, 202);
  }
}