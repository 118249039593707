.diagram-wrapper {
    background-color: rgb(4, 28, 50);
    color: rgb(202, 202, 202);
    padding-left: 10%;
    padding-right: 10%;
    margin-bottom: 0px;
}

.diagram-title {
    color: white;
    margin-bottom: 50px;
  }

.diagram {
    padding-top: 5%;
    padding-bottom: 5%;
    margin: auto;
    max-width: 1200px;
    text-align: left;
}

.diagram-pic-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.diagram-pic {
    width: 100%;
    object-fit: cover;
    height: auto;
}