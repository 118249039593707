.button {
  display: flex;
  /* background-color: none; */
  border-radius: 10px;
  border: solid;
  /* padding-top: 10px; */
  /* padding-bottom: 5px; */
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  /* text-align: center; */
  text-decoration: none;
  /* font-size: 15px; */
  transition: 0.3s;
  cursor: pointer;
}

.button:hover {
  transform: scale(1.1);
}

.resume-button-wrapper {
  display: flex;
  /* background-color: rgb(255, 219, 225); */
  /* background-color: rgb(164, 213, 255); */
  /* background-color: rgb(85, 132, 172); */
  /* background-color: rgb(6, 70, 99); */
  background-color: rgb(5, 54, 77);
  border-radius: 10px;
  border: solid;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  /* text-align: center; */
  text-decoration: none;
  /* font-size: 15px; */
  transition: 0.3s;
  cursor: pointer;
}

.resume-button-wrapper:hover {
  transform: scale(1.1);
}

.resume-icon {
  display: block;
  margin: auto;
  max-height: 40px;
  filter: invert(1);
}

.button-content {
  display: flex;
}

.button-desc {
  display: flex;
  flex-direction: column;
  justify-content: left;
  padding-left: 10px;
  padding-right: 10px;
  text-decoration: none;
  /* color: black; */
  color: rgb(202, 202, 202);
}

.button-icon {
  display: block;
  margin: auto;
  max-height: 40px;
}

.github-button-icon {
  display: block;
  margin: auto;
  max-height: 60px;
  filter: invert(1);
}

.icon {
  display: flex;
  background-color: white;
  /* background-color: rgb(202, 202, 202); */
  border-radius: 30px;
  border: solid;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 8px;
  padding-right: 8px;
  border: none;
  text-align: center;
  text-decoration: none;
  /* font-size: 15px; */
  transition: 0.3s;
}

.icon:hover {
  transform: scale(1.1);
}

.icon-pic {
  margin: auto;
  max-height: 40px;
}